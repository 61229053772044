import React, { useEffect } from 'react';
import { supabase } from '../service/supabase';
import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import { useNavigate } from 'react-router-dom';

interface CardProps {
  width?: number;
  height?: number;
  color?: string;
}

const Card: React.FC<CardProps> = () => {
  const navigate = useNavigate();
  useEffect(() => {
    // supabase.auth.getSession().then(({ data: { session } }) => {
    supabase.auth.getSession();

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      if (session) {
        navigate('/');
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [navigate]);

  return (
    <div className="bg-white min-w-96 py-4 px-6 max-w-sm border border-gray-200 rounded-lg shadow">
      <Auth
        supabaseClient={supabase}
        appearance={{ theme: ThemeSupa }}
        providers={[]}
      />
    </div>
  );
};

export default Card;
