import { SVGProps } from 'react';

const Engine = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#7E7E7E"
      d="M20.25 7.5h-1.5a.75.75 0 0 0-.75.75v2.25h-1.5V9c0-.827-.673-1.5-1.5-1.5h-3.75V6h3a.75.75 0 0 0 0-1.5h-7.5a.75.75 0 0 0 0 1.5h3v1.5H6c-.827 0-1.5.673-1.5 1.5v3H3V9a.75.75 0 0 0-1.5 0v7.5a.75.75 0 0 0 1.5 0v-3h1.5v2.625c0 .47.224.919.6 1.2L7.6 19.2c.258.194.577.3.9.3H15c.827 0 1.5-.673 1.5-1.5v-2.25H18V18c0 .414.336.75.75.75h1.5c1.24 0 2.25-1.01 2.25-2.25V9.75c0-1.24-1.01-2.25-2.25-2.25Zm.75 9c0 .413-.337.75-.75.75h-.75V15a.75.75 0 0 0-.75-.75h-3A.75.75 0 0 0 15 15v3H8.5L6 16.125V9h9v2.25c0 .414.336.75.75.75h3a.75.75 0 0 0 .75-.75V9h.75c.413 0 .75.337.75.75v6.75Z"
    />
    <path
      fill="#7E7E7E"
      d="M12.662 13.146c.13.243.116.54-.038.77l-1.5 2.25a.75.75 0 0 1-1.248-.833l.722-1.083H9a.75.75 0 0 1-.624-1.166l1.5-2.25a.75.75 0 1 1 1.248.833l-.722 1.083H12a.75.75 0 0 1 .662.396Z"
    />
  </svg>
);
export default Engine;
