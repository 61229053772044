import React, { useState } from 'react';
import Layout from '../../Layout';
import {
  useProducts,
  useBrandsList,
  useUniqModelsList,
} from '../../hooks/useProducts';
import { CarType } from '../../types';
import cn from 'classnames';
import {
  ClockIcon,
  ColorIcon,
  EngineIcon,
  TransmissionType,
} from '../../assets';
import Filtering from './Filtering';

const Cars: React.FC = () => {
  const [page, setPage] = useState(1);
  const [selectedBrand, setSelectedBrand] = useState<string | null>('');
  const [selectedModel, setSelectedModel] = useState<string>('');
  const [openAccordion, setOpenAccordion] = useState<number | null>(null);

  const { data: products } = useProducts(page, selectedBrand, selectedModel);
  const { data: brands } = useBrandsList();
  const { data: models } = useUniqModelsList(selectedBrand);

  const goToPreviousPage = () => setPage(page - 1);
  const goToNextPage = () => setPage(page + 1);

  const hasMore =
    products !== undefined && products !== null && !(100 > products.length);

  const toggleAccordion = (index: number) => {
    setOpenAccordion(openAccordion === index ? null : index);
  };

  return (
    <Layout>
      <Filtering
        brands={brands}
        models={models}
        setSelectedModel={setSelectedModel}
        setSelectedBrand={setSelectedBrand}
      />
      <div>
        <div id="accordion-collapse" data-accordion="collapse">
          {products &&
            products.map((car: CarType, index: number) => (
              <div key={car.id}>
                <h2 id={`accordion-collapse-heading-${car.id}`}>
                  <button
                    type="button"
                    className={cn(
                      'flex items-center justify-between w-full p-5 mt-4 font-medium text-gray-500 bg-[#F7F7F7] hover:bg-gray-100 gap-3',
                      {
                        'rounded-lg': openAccordion !== index, // Fully rounded when closed
                        'rounded-t-lg': openAccordion === index, // Rounded top when open
                      }
                    )}
                    onClick={() => toggleAccordion(index)}
                    aria-expanded={openAccordion === index}
                    aria-controls={`accordion-collapse-body-${car.id}`}
                  >
                    <div className="flex items-center xl:justify-between flex-wrap gap-8 w-full">
                      <div className="flex items-center sm:flex-row flex-col gap-5 sm:w-[351px]">
                        <img
                          className="rounded-lg w-16 h-16 sm:w-24 sm:h-24"
                          alt={car.name}
                          src={car.images[0]}
                        />
                        <h3 className="text-primary text-center sm:text-left font-semibold text-sm sm:text-base">
                          {car.name}
                        </h3>
                      </div>
                      <div className="flex items-center gap-2 text-left">
                        <ClockIcon />
                        <div>
                          <h4 className="font-montserrat text-primary text-base font-semibold">
                            Year
                          </h4>
                          <p className="font-montserrat text-primary text-xs font-normal">
                            {car.model_year}
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center gap-2 text-left">
                        <TransmissionType />
                        <div>
                          <h4 className="font-montserrat text-primary text-base font-semibold">
                            {car.transmission_type}
                          </h4>
                          <p className="font-montserrat text-primary text-xs font-normal">
                            Transmission Type
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center gap-2 text-left">
                        <ColorIcon />
                        <div>
                          <h4 className="font-montserrat text-primary text-base font-semibold">
                            {car.exterior_color}
                          </h4>
                          <p className="font-montserrat text-primary text-xs font-normal">
                            Color
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center gap-2 text-left">
                        <EngineIcon />
                        <div>
                          <h4 className="font-montserrat text-primary text-base font-semibold">
                            {car.engine_capacity_cc}
                          </h4>
                          <p className="font-montserrat text-primary text-xs font-normal">
                            Engine Capacity
                          </p>
                        </div>
                      </div>
                    </div>
                    <svg
                      className={`w-3 h-3 transform ${
                        openAccordion === index ? 'rotate-180' : ''
                      }`}
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 10 6"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9 5 5 1 1 5"
                      />
                    </svg>
                  </button>
                </h2>
                <div
                  id={`accordion-collapse-body-${car.id}`}
                  className={cn({
                    hidden: openAccordion !== index, // Hidden when the accordion is closed
                  })}
                  aria-labelledby={`accordion-collapse-heading-${car.id}`}
                >
                  <div
                    className={cn(
                      'p-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-8 border-t border-gray-200 mb-4 bg-background-1',
                      {
                        'rounded-b-lg': openAccordion === index, // Rounded when the accordion is open
                      }
                    )}
                  >
                    {car.fuel_type && (
                      <div className="flex items-center gap-2 text-left">
                        <div>
                          <h4 className="font-montserrat text-primary text-base font-semibold">
                            {car.fuel_type}
                          </h4>
                          <p className="font-montserrat text-primary text-xs font-normal">
                            Fuel Type
                          </p>
                        </div>
                      </div>
                    )}

                    {car.body_type && (
                      <div className="flex items-center gap-2 text-left">
                        <div>
                          <h4 className="font-montserrat text-primary text-base font-semibold">
                            {car.body_type}
                          </h4>
                          <p className="font-montserrat text-primary text-xs font-normal">
                            Body Type
                          </p>
                        </div>
                      </div>
                    )}

                    {car.horsepower && (
                      <div className="flex items-center gap-2 text-left">
                        <div>
                          <h4 className="font-montserrat text-primary text-base font-semibold">
                            {car.horsepower}
                          </h4>
                          <p className="font-montserrat text-primary text-xs font-normal">
                            Horsepower
                          </p>
                        </div>
                      </div>
                    )}

                    {car.kilometers && (
                      <div className="flex items-center gap-2 text-left">
                        <div>
                          <h4 className="font-montserrat text-primary text-base font-semibold">
                            {car.kilometers}
                          </h4>
                          <p className="font-montserrat text-primary text-xs font-normal">
                            Kilometers
                          </p>
                        </div>
                      </div>
                    )}

                    {car.doors && (
                      <div className="flex items-center gap-2 text-left">
                        <div>
                          <h4 className="font-montserrat text-primary text-base font-semibold">
                            {car.doors}
                          </h4>
                          <p className="font-montserrat text-primary text-xs font-normal">
                            Doors
                          </p>
                        </div>
                      </div>
                    )}

                    {car.steering_side && (
                      <div className="flex items-center gap-2 text-left">
                        <div>
                          <h4 className="font-montserrat text-primary text-base font-semibold">
                            {car.steering_side}
                          </h4>
                          <p className="font-montserrat text-primary text-xs font-normal">
                            Steering Side
                          </p>
                        </div>
                      </div>
                    )}

                    {car.seller_type && (
                      <div className="flex items-center gap-2 text-left">
                        <div>
                          <h4 className="font-montserrat text-primary text-base font-semibold">
                            {car.seller_type}
                          </h4>
                          <p className="font-montserrat text-primary text-xs font-normal">
                            Seller
                          </p>
                        </div>
                      </div>
                    )}

                    {car.warranty && (
                      <div className="flex items-center gap-2 text-left">
                        <div>
                          <h4 className="font-montserrat text-primary text-base font-semibold">
                            {car.warranty}
                          </h4>
                          <p className="font-montserrat text-primary text-xs font-normal">
                            Warranty
                          </p>
                        </div>
                      </div>
                    )}

                    {car.target_market && (
                      <div className="flex items-center gap-2 text-left">
                        <div>
                          <h4 className="font-montserrat text-primary text-base font-semibold">
                            {car.target_market}
                          </h4>
                          <p className="font-montserrat text-primary text-xs font-normal">
                            Target Market
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>
        {selectedBrand === '' && (
          <nav
            className="bg-white px-4 py-3 flex items-center justify-between border-t border-b md:rounded-bt-lg border-gray-200 sm:px-6"
            aria-label="Pagination"
          >
            <div className="flex-1 flex justify-between sm:justify-end">
              <button
                className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                disabled={page === 1}
                onClick={goToPreviousPage}
              >
                Previous
              </button>
              <button
                className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                disabled={!hasMore}
                onClick={goToNextPage}
              >
                Next
              </button>
            </div>
          </nav>
        )}
      </div>
    </Layout>
  );
};

export default Cars;
