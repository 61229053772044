import React from 'react'; // , { useEffect, useState }
import Layout from '../../Layout';
import { useSupabaseAuth } from '../../hooks/useSupabaseAuth';
// import { useNavigate } from 'react-router-dom';

const Cars: React.FC = () => {
  // const [seconds, setSeconds] = useState(6); // Set initial countdown time (in seconds)
  const { session } = useSupabaseAuth();
  // const navigate = useNavigate();

  // useEffect(() => {
  //   if (!session) {
  //     setSeconds(5); // Reset seconds to 5 when session is present
  //     const countdown = setInterval(() => {
  //       setSeconds((prev) => {
  //         if (prev <= 1) {
  //           clearInterval(countdown);
  //           navigate('/login'); // Redirect to dashboard
  //           return 0;
  //         }
  //         return prev - 1;
  //       });
  //     }, 1000);

  //     return () => clearInterval(countdown); // Cleanup interval on unmount
  //   }
  // }, [session, navigate]);

  return (
    <Layout>
      <div className="overflow-x-auto">
        {/* {!session ? (
          <div className="flex align-center justify-center">
            <div className="mt-20">
              <p className="text-center mb-2">
                In order to see this page you will haev to login first.
              </p>
              <p className="text-center">
                You will be redirected to the dashboard in {seconds} seconds.
              </p>
            </div>
          </div>
        ) : ( */}
        <iframe
          title="Cars"
          className="m-auto"
          width="1300"
          height="1745"
          src="https://lookerstudio.google.com/embed/reporting/b5ff6921-96e2-4f36-87c3-1a0d36f141e8/page/p_1syyzngsld"
          frameBorder="0"
          style={{ border: '0' }}
          allowFullScreen
          sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
        ></iframe>
        {/* )} */}
      </div>
    </Layout>
  );
};

export default Cars;
