import { useState } from 'react';
import Dialog from '../../components/Dialog';
import Select from '../../components/Select';
import { useSupabaseAuth } from '../../hooks/useSupabaseAuth';
// import { useNavigate } from 'react-router-dom';

interface FiltersProps {
  setSelectedModel: React.Dispatch<React.SetStateAction<string>>;
  setSelectedBrand: React.Dispatch<React.SetStateAction<string | null>>;
  models?: { value: string; label: string }[];
  brands?: { value: string; label: string }[];
}

const Filtering: React.FC<FiltersProps> = ({
  setSelectedModel,
  setSelectedBrand,
  brands,
  models,
}) => {
  const [open, setOpen] = useState(false);
  const [seconds, setSeconds] = useState(6); // Set initial countdown time (in seconds)
  // const { session } = useSupabaseAuth();
  // const navigate = useNavigate();

  return (
    <>
      <div className="mt-2 flex gap-3 items-center">
        <h2 className="font-extrabold text-xl text-primary">Filter</h2>
        {/* <div className="relative w-full flex">
          <label
            htmlFor="start_price"
            className="block absolute text-sm font-normal text-primary font-montserrat top-[-18px] left-[10px] bg-white p-2"
          >
            Price
          </label>
          <input
            type="text"
            id="start_price"
            className=" bg-white border border-border-color text-gray-900 text-sm rounded-l-full focus:ring-blue-500 focus:border-blue-500 block w-full p-4"
            placeholder="From"
            required
          />
           <input
            type="text"
            id="end_price"
            className=" bg-white border border-border-color text-gray-900 text-sm rounded-r-full focus:ring-blue-500 focus:border-blue-500 block w-full p-4"
            placeholder="To"
            required
          />
        </div> */}
        <Select
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            // if (!session) {
            //   setOpen(true);
            //   setSeconds(5); // Reset seconds to 5 when session is present
            //   const countdown = setInterval(() => {
            //     setSeconds((prev) => {
            //       if (prev <= 1) {
            //         clearInterval(countdown);
            //         navigate('/login'); // Redirect to dashboard
            //         return 0;
            //       }
            //       return prev - 1;
            //     });
            //   }, 1000);
            //   return;
            // }
            setSelectedBrand(e.target.value);
            setSelectedModel('');
          }}
          label="Maker"
          options={brands || []}
        />
        <Select
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            // if (!session) {
            //   setOpen(true);
            //   setSeconds(5); // Reset seconds to 5 when session is present
            //   const countdown = setInterval(() => {
            //     setSeconds((prev) => {
            //       if (prev <= 1) {
            //         clearInterval(countdown);
            //         navigate('/login'); // Redirect to dashboard
            //         return 0;
            //       }
            //       return prev - 1;
            //     });
            //   }, 1000);
            //   return;
            // }
            setSelectedModel(e.target.value);
          }}
          label="Modal"
          options={models || []}
        />

        {/* <button
        type="button"
        className="p-4 me-2 mb-2 text-sm font-medium w-[430px] text-[#5459EA] focus:outline-none bg-white rounded-full border border-[#5459EA] hover:text-white hover:bg-[#5459EA] focus:z-10 focus:ring-4 focus:ring-gray-100 "
      >
        Apply
      </button>
      <button
        type="button"
        className="p-4 me-2 flex items-center justify-between w-[600px] mb-2 text-sm font-medium text-[#505470] focus:outline-none bg-white rounded-full hover:bg-[#f0f0f0] focus:z-10 focus:ring-4 focus:ring-gray-100 "
      >
        Advance Filter
        <Filter />
      </button> */}
      </div>
      <Dialog setOpen={setOpen} title={'Please Login first'} open={open}>
        <p className="text-center  text-sm text-gray-500 mb-2">
          In order to see this page you will haev to login first.
        </p>
        <p className="text-center  text-sm text-gray-500">
          You will be redirected to the dashboard in {seconds} seconds.
        </p>
      </Dialog>
    </>
  );
};

export default Filtering;
