import { useNavigate } from 'react-router-dom';
import { Doors, Menu, LoginIcon } from '../../assets';
import { useSupabaseAuth } from '../../hooks/useSupabaseAuth';
import { supabase } from '../../service/supabase';

const Header = ({
  setHideSidebar,
  hideSidebar,
}: {
  setHideSidebar: (val: boolean) => void;
  hideSidebar: boolean;
}) => {
  const { session } = useSupabaseAuth();
  const navigate = useNavigate();
  const signOut = async () => {
    await supabase.auth.signOut();
  };

  return (
    <div className="flex items-center justify-between border-b pb-6 border-light-blue">
      <div className="flex gap-6 items-center">
        <span
          onClick={() => setHideSidebar(!hideSidebar)}
          className="cursor-pointer"
        >
          {' '}
          <Menu />
        </span>
        <p className="font-montserrat font-normal text-2xl text-primary">
          Hello 👋
        </p>
      </div>
      <div>
        {/* {session ? (
          <button className="border-none" onClick={signOut}>
            <Doors />
          </button>
        ) : (
          <button
            className="border-none"
            onClick={() => {
              navigate("/login");
            }}
          >
            <LoginIcon />
          </button>
        )} */}
      </div>
    </div>
  );
};

export default Header;
