import { useQuery } from 'react-query';
import { supabase } from '../service/supabase';

async function getAllProducts(
  page: number,
  selectedBrand: string | null,
  selectedModel: string | null
) {
  let products = [];
  if (selectedBrand === '') {
    const start = (page - 1) * 100;
    const end = page * 100 - 1;
    const { data, error } = await supabase
      .from('temp_adds_pool')
      .select('*')
      .range(start, end)
      .neq('postedOnDate', null);

    if (error) {
      throw error;
    }
    if (data) {
      products = data;
    }
    return products;
  } else {
    const { data, error } = await supabase.rpc('get_filtered_products', {
      filter_brand: selectedBrand || null,
      filter_model: selectedModel || null,
      // page_size: 100,
      // page_number: page,
    });
    if (error) {
      throw error;
    }
    if (data) {
      products = data;
    }
    return products;
  }
}

async function getUniqueBrands() {
  try {
    const { data, error } = await supabase.rpc('get_unique_brands');
    if (error) {
      throw error;
    }

    return data.map(({ brand }: { brand: string }) => {
      return { value: brand, label: brand };
    });
  } catch (error) {
    console.log('error :', error);
  }
}

async function getModelsForBrand(brandName: string | null) {
  try {
    if (!brandName) {
      return [];
    }

    const { data, error } = await supabase.rpc('get_unique_models', {
      brand_name: brandName,
    });

    if (error) {
      throw error;
    }

    return data.map(({ model }: { model: string }) => {
      return { value: model, label: model };
    });
  } catch (error) {
    console.log('error :', error);
  }
}

export function useProducts(
  page: number,
  selectedBrand: string | null = '',
  selectedModel: string | null = ''
) {
  return useQuery(['products', page, selectedBrand, selectedModel], () =>
    getAllProducts(page, selectedBrand, selectedModel)
  );
}

export function useBrandsList() {
  return useQuery(['uniqBrands'], () => getUniqueBrands());
}

export function useUniqModelsList(brandName: string | null = null) {
  return useQuery(['uniqModels', brandName], () =>
    getModelsForBrand(brandName)
  );
}
