import React from 'react';

interface MainHeadingProps {
  text: string;
  width?: number;
  height?: number;
  color?: string;
  align?: 'left' | 'center' | 'right';
  lineHeight?: number;
  fontsize?: number;
  fontName?: string;
  weight?: number;
  paddingBottom?: number;
}

const MainHeading: React.FC<MainHeadingProps> = ({
  text,
  width = 200,
  height = 59,
  color = 'black',
  align = 'center',
  lineHeight = 58.51,
  fontsize = 24,
  fontName = 'Montserrat',
  weight = 300,
  paddingBottom = 0,
}) => {
  return (
    <h1
      style={{
        width: `${width}px`,
        height: `${height}px`,
        color: color,
        lineHeight: `${lineHeight}px`,
        textAlign: align,
        fontSize: `${fontsize}px`,
        fontFamily: fontName,
        fontWeight: weight,
        paddingBottom: `${paddingBottom}px`,
      }}
    >
      {text}
    </h1>
  );
};

export default MainHeading;
