const Select = ({
  label,
  options,
  onChange,
  onClick,
  ...props
}: {
  label: string;
  onClick?: () => void;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  options: { label: string; value: string }[];
}) => {
  return (
    <div className="w-full relative">
      {/* dark:text-white */}
      <label
        htmlFor="countries"
        className="block absolute text-sm font-normal text-primary font-montserrat top-[-18px] left-[10px] bg-white p-2"
      >
        {label}
      </label>
      {/* dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 */}
      <select
        onClick={onClick}
        {...props}
        id="countries"
        onChange={onChange}
        className="select bg-white border border-border-color text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-4"
      >
        <option selected>Choose Option</option>
        {options?.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
