import Card from '../../components/Card';
import MainHeading from '../../components/MainHeading';

const Login = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <MainHeading
        text="Istibana"
        color="white"
        width={412}
        height={59}
        fontsize={48}
        weight={600}
        lineHeight={58.51}
        paddingBottom={70}
      />
      <Card width={412} height={530} />
    </div>
  );
};

export default Login;
