import { ReactNode, useEffect, useState } from "react";
import Sidebar from "./components/Sidebar";
import Header from "./pages/Cars/Header";

const Layout = ({ children }: { children: ReactNode }) => {
  const [hideSidebar, setHideSidebar] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 767) {
        setHideSidebar(true);
      } else {
        setHideSidebar(false);
      }
    };

    // Set initial state based on screen size
    handleResize();

    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="bg-black ">
      {hideSidebar ? null : <Sidebar setHideSidebar={setHideSidebar} />}
      <div
        className={`py-7 pr-6 transition-all duration-300 ease-in-out ${
          hideSidebar ? "pl-6" : "sm:ml-72"
        }`}
      >
        <div className="p-4 bg-white min-h-screen rounded-[30px]">
          <Header setHideSidebar={setHideSidebar} hideSidebar={hideSidebar} />
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;
