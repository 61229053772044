import { createBrowserRouter } from 'react-router-dom';
import Login from './pages/Login/Login';
import Cars from './pages/Cars/Cars';
import Dashboard from './pages/Dashboard/Dashboard';

export const router = createBrowserRouter([
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/',
    element: <Dashboard />,
  },
  {
    path: '/cars',
    element: <Cars />,
  },
]);
